function init(container) {

	function initialView() {
		if (jQuery(window).width() >= 1025) {
			return 'dayGridMonth';
		}
		if (jQuery(window).width() < 1025) {
			return 'listWeek';
		}
	}

	const monthNames = ["ЯНВ", "ФЕВ", "МАР", "АПР", "МАЙ", "ИЮН",
		"ИЮЛ", "АВГ", "СЕН", "ОКТ", "НОЯ", "ДЕК"
	];

	let calendarEl = document.getElementById('event-calendar');

	if(calendarEl) {
		let calendar = new FullCalendar.Calendar(calendarEl, {
			initialView: initialView(),
			customButtons: {
				categoryList: {
					text: 'custom!',
					click: function () {

					}
				}
			},
			locale: 'ru',
			headerToolbar: {
				left: 'prev title next',
				center: '',
				right: ''
			},
			footerToolbar: false,
			buttonText: {
				today: 'Сегодня',
				month: 'Месяц',
				week: 'Неделя',
				day: 'День',
				list: 'Неделя'
			},
			allDayText: 'Весь день',
			editable: false,
			selectable: false,
			dayHeaders: true,
			dayCellContent: function (arg) {
				arg.dayNumberText = arg.date.getDate() + ' ' + monthNames[arg.date.getMonth()].toLowerCase();
			},
			moreLinkContent: function (arg) {
				arg.text = '+ еще ' + arg.num;
			},
			contentHeight: 'auto',
			expandRows: true,
			firstDay: 1,
			slotMinTime: '06:00',
			slotMaxTime: '24:00',
			navLinks: false,
			nowIndicator: false,
			noEventsText: 'В выбранное время мероприятий нет.',
			dayMaxEvents: true,
			dayMaxEventRows: 5,
			fixedWeekCount: false,
			eventTimeFormat: {
				hour: '2-digit',
				minute: '2-digit',
				hour12: false
			},
			displayEventEnd: true,
			eventOrder: "-duration",
			eventDidMount: function(info) {
				let eventStart = new Date(info.event.start).toISOString().slice(0, 10);
				let eventEnd = new Date(info.event.end).toISOString().slice(0, 10);

				let dayCell = info.el.closest('.fc-daygrid-day');
				let dayCellDate = new Date($(info.el).closest('.fc-daygrid-day').data('date'));

				let weekStart = $(info.el).closest('tr').find('.fc-daygrid-day:first-child').data('date');
				let weekEnd = $(info.el).closest('tr').find('.fc-daygrid-day:last-child').data('date');

				let events = calendar.getEvents();
				let dayEvents = events.filter(function(event) {
					return event.start.toDateString() === info.event.start.toDateString();
				})

				let eventCount = dayEvents.length;

				if (dayCell) {
					if (eventCount >= 4) {
						dayCell.classList.add('fc-day-event-more');

						if($(dayCell).hasClass('fc-day-event-more')) {
							/*
							setTimeout(function (){
								if($(dayCell).find('.fc-day-one-day-events')) {
									$(dayCell).find('.fc-day-one-day-events').remove();
									$(dayCell).find('.fc-daygrid-day-events').append('<div class="fc-day-one-day-events"></div>');

									$(dayCell).find('.fc-daygrid-event-harness:not(.fc-daygrid-event-harness-abs)').appendTo($('.fc-day-one-day-events'));
								}
							},50)
							*/
						} else {
							console.log(2)
						}
					}
					if (eventStart === eventEnd) {
						info.el.classList.add('fc-day-one-day-event');
					}
					if (eventStart === weekStart) {
						info.el.classList.add('fc-day-week-start-event');
					}
					if (eventEnd === weekEnd) {
						info.el.classList.add('fc-day-week-end-event');
					}
				}

				if($(info.el).closest('.fc-daygrid-event-harness').hasClass('fc-daygrid-event-harness-abs')) {
					let startDate = new Date(info.event.start);
					startDate.setHours(0, 0, 0, 0);
					let endDate = new Date(info.event.end);
					endDate.setHours(23, 59, 59, 999);

					let eventDuration = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24) + 1;

					let weekStart = new Date($(info.el).closest('tr').find('.fc-daygrid-day:first-child').data('date'));
					let weekEnd = new Date($(info.el).closest('tr').find('.fc-daygrid-day:last-child').data('date'));
					weekEnd.setHours(23, 59, 59, 999);

					let daysInWeek = 0;

					if (startDate <= weekStart && endDate >= weekEnd) {
						daysInWeek = 7;
					} else if (startDate <= weekStart && endDate >= weekStart && endDate <= weekEnd) {
						daysInWeek = (endDate - weekStart) / (1000 * 3600 * 24) + 1;
					} else if (startDate >= weekStart && endDate <= weekEnd){
						daysInWeek = eventDuration;
					} else if (startDate <= weekEnd && endDate >= weekEnd && startDate >= weekStart) {
						daysInWeek = (weekEnd - startDate) / (1000 * 3600 * 24) + 1;
					}

					daysInWeek = Math.ceil(daysInWeek) !== 7 ? Math.ceil(daysInWeek) - 1 : 7;

					let classes = info.el.className;

					let eventLinks = '';
					for (let i=0; i< daysInWeek; i++) {
						let dats = moment(dayCellDate).toDate();
						let dayCellDateString = moment(dats).add(i, 'days').format("YYYY-MM-DD");

						eventLinks+= `<a href="${info.event._def.url}" class="fc-event-main-link" data-date="${dayCellDateString}"></a>`;
					}

					$(info.el).closest('.fc-daygrid-event-harness').html(
						`
							<div
								class="${classes}"
								style="background-color: ${info.backgroundColor};"
							>
								<div class="fc-event-main-links">${eventLinks}</div>
								<div class="fc-event-main">
									<div class="fc-event-main-frame">
										<div class="fc-event-title-container">
											<div class="fc-event-title fc-sticky">${info.event._def.title}</div>
										</div>
									</div>
								</div>
							</div>
						`
					);
					setTimeout(() => {
						$(info.el).remove();
					}, 5);
				}
			},
			eventClick: function (info) {
				let dayCell = info.el.closest('.fc-daygrid-day');
				let dayCellDate = new Date($(info.el).closest('.fc-daygrid-day').data('date'));
				let dayCellDateString = new Date(dayCellDate).toDateString();

				let events = calendar.getEvents();
				let dayEvents = events.filter(function (event) {
					let start = new Date(event._instance.range.start).toDateString();
					let end = new Date(event._instance.range.end).toDateString();
					return Date.parse(start) <= Date.parse(dayCellDateString) && Date.parse(end) >= Date.parse(dayCellDateString);
				});
				let sortedEvents = dayEvents.sort(function(a, b) {
					let durationA = moment(a.end).diff(moment(a.start));
					let durationB = moment(b.end).diff(moment(b.start));
					return durationB - durationA;
				});

				let eventCount = dayEvents.length;

				if (dayCell) {
					if (eventCount >= 2) {
						info.jsEvent.preventDefault();

						$('.fc-popover').remove();

						let day = $(info.el).closest('.fc-daygrid-day');
						let dayDate = day.find('.fc-daygrid-day-number').text();
						let eventsHtml = sortedEvents.map((event) => {
							return `
								<div class="fc-daygrid-event-harness">
									<a
										href="${event.url}"
										target="_blank"
										class="fc-daygrid-event fc-daygrid-block-event fc-h-event fc-event fc-event-start fc-event-end fc-event-future"
										style="background-color: ${event.backgroundColor};"
									>
										<div class="fc-event-main">
											<div class="fc-event-main-frame">
												<div class="fc-event-time">09:30 - 17:00</div>
												<div class="fc-event-title-container">
													<div class="fc-event-title fc-sticky">${event.title}</div>
												</div>
											</div>
										</div>
									</a>
								</div>
							`;
						});

						let popup =
							`
								<div class="fc-popover fc-more-popover fc-day fc-day-tue fc-day-future">
									<div class="fc-popover-header ">
										<span class="fc-popover-title">${dayDate}</span>
										<span class="fc-popover-close fc-icon fc-icon-x"></span>
									</div>
									<div class="fc-popover-body">${eventsHtml.join('')}</div>
									<div class="fc-popover-bg"></div>
								</div>
						`;

						day.append(popup);

						let popover = $('.fc-popover');

						let dayRect = day[0].getBoundingClientRect();
						let containerRect = $('.fc-daygrid-body')[0].getBoundingClientRect();
						let hoverTop;
						let hoverLeft;

						if (dayRect.bottom + popover.outerHeight() > containerRect.bottom) {
							hoverTop = (dayRect.bottom - dayRect.top) + dayRect.top - containerRect.top - popover.outerHeight() - 10; // нижняя граница
						} else {
							hoverTop = dayRect.top - containerRect.top; // верхняя граница
						}

						if (dayRect.right + popover.outerWidth() > containerRect.right) {
							hoverLeft = dayRect.left - containerRect.left - popover.outerWidth() - 10; // Влево
						} else if (dayRect.left - popover.outerWidth() < containerRect.left) {
							hoverLeft = dayRect.left - containerRect.left + dayRect.width; // Вправо
						} else {
							hoverLeft = dayRect.left - containerRect.left + dayRect.width; // Вправо
						}

						popover.css({ top: hoverTop, left: hoverLeft });

					} else if (info.event.url) {
						info.jsEvent.preventDefault();

						window.open(info.event.url, "_blank");
						return false;
					}
				}
			},
			events: function (info, successCallback) {
				/*let doc = [
					{
						"id": "54",
						"title": "Демо-день ИЦК «Химия»",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-07-18T00:00:00",
						"end": "2023-07-18T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/voenno-tehnicheskij-forum-armija-2023/",
						"terms": [
							"Демо-день ИЦК"
						]
					},
					{
						"id": "97",
						"title": "Демо-день ИЦК «Геологоразведка. Недропользование»",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-08-16T00:00:00",
						"end": "2023-08-16T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/demo-den-ick-geologorazvedka-nedropolzovanie/",
						"terms": [
							"Демо-день ИЦК"
						]
					},
					{
						"id": "57",
						"title": "Демо-день ИЦК «ЖКХ»",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-08-23T00:00:00",
						"end": "2023-08-23T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/hcs1/",
						"terms": [
							"Демо-день ИЦК"
						]
					},
					{
						"id": "59",
						"title": "Демо-день ИЦК «Автомобилестроение»",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-09-07T00:00:00",
						"end": "2023-09-07T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://ицкавто.рф/",
						"terms": [
							"Демо-день ИЦК"
						]
					},
					{
						"id": "155",
						"title": "Промышленно-энергетический форум TNF Forum",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-09-18T00:00:00",
						"end": "2023-09-21T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://oilgasforum.ru/",
						"terms": [
							"Форум"
						]
					},
					{
						"id": "153",
						"title": "Международный форум Kazan Digital Week",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-09-20T00:00:00",
						"end": "2023-09-22T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://kazandigitalweek.com/ru/site",
						"terms": [
							"Форум"
						]
					},
					{
						"id": "152",
						"title": "Э+Аналитика",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-09-27T00:00:00",
						"end": "2023-09-27T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/je-analitika/",
						"terms": [
							""
						]
					},
					{
						"id": "157",
						"title": "Форум SatCommRus",
						"backgroundColor": "#1A6DB4",
						"textColor": "#ffffff",
						"borderColor": "#1A6DB4",
						"color": "#ffffff",
						"start": "2023-09-28T00:00:00",
						"end": "2023-09-29T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://satcomrus.ru/",
						"terms": [
							"Форум"
						]
					},
					{
						"id": "184",
						"title": "Демо-день ИЦК «Нефтегаз, нефтехимия и недропользование»",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-09-28T00:00:00",
						"end": "2023-09-28T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://byteoilconf.ru/2023",
						"terms": [
							"Демо-день ИЦК"
						]
					},
					{
						"id": "179",
						"title": "Демо-день ИЦК АПК",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-10-06T00:00:00",
						"end": "2023-10-06T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://ick-apk.ru/",
						"terms": [
							"Демо-день ИЦК"
						]
					},
					{
						"id": "191",
						"title": "Демо-день ИЦК «Экология»",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-10-10T00:00:00",
						"end": "2023-10-10T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://reo.ru/ref2023/#registration",
						"terms": [
							"Демо-день ИЦК"
						]
					},
					{
						"id": "147",
						"title": "Демо-день ИЦК «Мобильная связь»",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-10-12T00:00:00",
						"end": "2023-10-12T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "http://мобильнаясвязь.рф/",
						"terms": [
							"Демо-день ИЦК"
						]
					},
					{
						"id": "186",
						"title": "Демо-день ИЦК «Двигателестроение»",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-10-12T00:00:00",
						"end": "2023-10-12T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://ицк-двигателестроение.рф/",
						"terms": [
							"Демо-день ИЦК"
						]
					},
					{
						"id": "159",
						"title": "Старт «Урока цифры» от Сбера",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-10-18T00:00:00",
						"end": "2023-11-20T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://xn--h1adlhdnlo2c.xn--p1ai/",
						"terms": [
							""
						]
					},
					{
						"id": "201",
						"title": "China International Import Expo (CIIE-2022)",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-11-05T00:00:00",
						"end": "2023-11-10T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://www.ciie.org/zbh/en/",
						"terms": [
							""
						]
					},
					{
						"id": "204",
						"title": "Международный муниципальный форум стран Brics",
						"backgroundColor": "#1A6DB4",
						"textColor": "#ffffff",
						"borderColor": "#1A6DB4",
						"color": "#ffffff",
						"start": "2023-11-09T00:00:00",
						"end": "2023-11-10T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://imbricsforum.com/",
						"terms": [
							""
						]
					},
					{
						"id": "206",
						"title": "Web Summit 2023",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-11-13T00:00:00",
						"end": "2023-11-16T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://websummit.com/",
						"terms": [
							""
						]
					},
					{
						"id": "207",
						"title": "гуляния 7",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-11-14T00:00:00",
						"end": "2023-11-24T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/guljanija-7/",
						"terms": [
							""
						]
					},
					{
						"id": "205",
						"title": "Форум Цифротех",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-11-22T00:00:00",
						"end": "2023-11-29T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://ctexpo.ru/",
						"terms": [
							""
						]
					},
					{
						"id": "254",
						"title": "Всероссийская встреча всех музыкантов 2023-1",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-11-23T00:00:00",
						"end": "2023-11-23T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vserossijskaja-vstrecha-vseh-muzykantov-2023-1/",
						"terms": [
							""
						]
					},
					{
						"id": "200",
						"title": "ИЦК- повестка",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-11-25T00:00:00",
						"end": "2023-11-28T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/ick-povestka/",
						"terms": [
							""
						]
					},
					{
						"id": "203",
						"title": "ИТ-диалог",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-11-25T00:00:00",
						"end": "2023-11-28T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://итдиалог.рф/",
						"terms": [
							""
						]
					},
					{
						"id": "267",
						"title": "Тест 1",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-11-30T00:00:00",
						"end": "2023-11-30T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/test-1/",
						"terms": [
							""
						]
					},
					{
						"id": "268",
						"title": "Тест2",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-11-30T00:00:00",
						"end": "2023-11-30T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/test2/",
						"terms": [
							""
						]
					},
					{
						"id": "269",
						"title": "Тест3",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-11-30T00:00:00",
						"end": "2023-11-30T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/test3/",
						"terms": [
							""
						]
					},
					{
						"id": "270",
						"title": "Тест4",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-11-30T00:00:00",
						"end": "2023-11-30T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/test4/",
						"terms": [
							""
						]
					},
					{
						"id": "271",
						"title": "Тест5",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-11-30T00:00:00",
						"end": "2023-11-30T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/test5/",
						"terms": [
							""
						]
					},
					{
						"id": "208",
						"title": "Гуляния 8",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-01T00:00:00",
						"end": "2023-12-01T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/guljanija-8/",
						"terms": [
							""
						]
					},
					{
						"id": "216",
						"title": "Важное мероприятие 1",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-01T00:00:00",
						"end": "2023-12-01T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vazhnoe-meroprijatie-1/",
						"terms": [
							""
						]
					},
					{
						"id": "217",
						"title": "Важное мероприятие 2",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-01T00:00:00",
						"end": "2023-12-01T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vazhnoe-meroprijatie-2/",
						"terms": [
							""
						]
					},
					{
						"id": "218",
						"title": "Важное мероприятие 3",
						"backgroundColor": "#1A6DB4",
						"textColor": "#ffffff",
						"borderColor": "#1A6DB4",
						"color": "#ffffff",
						"start": "2023-12-01T00:00:00",
						"end": "2023-12-02T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vazhnoe-meroprijatie-3/",
						"terms": [
							""
						]
					},
					{
						"id": "219",
						"title": "Важное мероприятие 4",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-12-01T00:00:00",
						"end": "2023-12-04T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vazhnoe-meroprijatie-4/",
						"terms": [
							""
						]
					},
					{
						"id": "265",
						"title": "Важное мероприятие 4",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-12-01T00:00:00",
						"end": "2023-12-05T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vazhnoe-meroprijatie-4-2/",
						"terms": [
							""
						]
					},
					{
						"id": "266",
						"title": "Важное мероприятие 3",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-12-01T00:00:00",
						"end": "2023-12-04T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vazhnoe-meroprijatie-3-2/",
						"terms": [
							""
						]
					},
					{
						"id": "264",
						"title": "всероссийская выставка достижений приборостроения 4",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-12-03T00:00:00",
						"end": "2023-12-05T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vserossijskaja-vystavka-dostizhenij-priborostroenija-4/",
						"terms": [
							""
						]
					},
					{
						"id": "293",
						"title": "Новогоднее",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-04T00:00:00",
						"end": "2023-12-04T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/novogodnee/",
						"terms": [
							""
						]
					},
					{
						"id": "257",
						"title": "предновогодье",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-05T00:00:00",
						"end": "2023-12-05T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/prednovogode/",
						"terms": [
							""
						]
					},
					{
						"id": "258",
						"title": "предновогодье 2",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-05T00:00:00",
						"end": "2023-12-05T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/prednovogode-2/",
						"terms": [
							""
						]
					},
					{
						"id": "259",
						"title": "предновогодье 3",
						"backgroundColor": "#1A6DB4",
						"textColor": "#ffffff",
						"borderColor": "#1A6DB4",
						"color": "#ffffff",
						"start": "2023-12-05T00:00:00",
						"end": "2023-12-06T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/prednovogode-3/",
						"terms": [
							""
						]
					},
					{
						"id": "260",
						"title": "предновогодье 4",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-12-05T00:00:00",
						"end": "2023-12-07T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/prednovogode-4/",
						"terms": [
							""
						]
					},
					{
						"id": "238",
						"title": "Пиу1",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-11T00:00:00",
						"end": "2023-12-11T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/piu1/",
						"terms": [
							""
						]
					},
					{
						"id": "239",
						"title": "Пиу2",
						"backgroundColor": "#1A6DB4",
						"textColor": "#ffffff",
						"borderColor": "#1A6DB4",
						"color": "#ffffff",
						"start": "2023-12-11T00:00:00",
						"end": "2023-12-12T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/piu2/",
						"terms": [
							""
						]
					},
					{
						"id": "240",
						"title": "Пиу3",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-12-11T00:00:00",
						"end": "2023-12-13T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/piu3/",
						"terms": [
							""
						]
					},
					{
						"id": "261",
						"title": "всероссийская выставка достижений приборостроения",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-14T00:00:00",
						"end": "2023-12-14T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vserossijskaja-vystavka-dostizhenij-priborostroenija/",
						"terms": [
							""
						]
					},
					{
						"id": "262",
						"title": "всероссийская выставка достижений приборостроения 2",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-14T00:00:00",
						"end": "2023-12-14T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vserossijskaja-vystavka-dostizhenij-priborostroenija-2/",
						"terms": [
							""
						]
					},
					{
						"id": "263",
						"title": "всероссийская выставка достижений приборостроения 3",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-14T00:00:00",
						"end": "2023-12-14T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vserossijskaja-vystavka-dostizhenij-priborostroenija-3/",
						"terms": [
							""
						]
					},
					{
						"id": "220",
						"title": "Важное мероприятие 10",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-16T00:00:00",
						"end": "2023-12-16T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vazhnoe-meroprijatie-5/",
						"terms": [
							""
						]
					},
					{
						"id": "222",
						"title": "Мероприятие 11",
						"backgroundColor": "#1A6DB4",
						"textColor": "#ffffff",
						"borderColor": "#1A6DB4",
						"color": "#ffffff",
						"start": "2023-12-16T00:00:00",
						"end": "2023-12-17T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/meroprijatie-11/",
						"terms": [
							""
						]
					},
					{
						"id": "223",
						"title": "Важное мероприятие 12",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-16T00:00:00",
						"end": "2023-11-19T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vazhnoe-meroprijatie-12/",
						"terms": [
							""
						]
					},
					{
						"id": "224",
						"title": "Мероприятие25",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-12-19T00:00:00",
						"end": "2023-12-22T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/meroprijatie25/",
						"terms": [
							""
						]
					},
					{
						"id": "225",
						"title": "Мероприятие26",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-19T00:00:00",
						"end": "2023-12-19T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/meroprijatie26/",
						"terms": [
							""
						]
					},
					{
						"id": "226",
						"title": "Мероприятие27",
						"backgroundColor": "#1A6DB4",
						"textColor": "#ffffff",
						"borderColor": "#1A6DB4",
						"color": "#ffffff",
						"start": "2023-12-19T00:00:00",
						"end": "2023-12-20T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/meroprijatie27/",
						"terms": [
							""
						]
					},
					{
						"id": "291",
						"title": "день",
						"backgroundColor": "#BA336E",
						"textColor": "#ffffff",
						"borderColor": "#BA336E",
						"color": "#ffffff",
						"start": "2023-12-19T00:00:00",
						"end": "2023-12-19T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/den/",
						"terms": [
							""
						]
					},
					{
						"id": "255",
						"title": "Всероссийская встреча всех музыкантов 2023-2",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-23T00:00:00",
						"end": "2023-12-23T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vserossijskaja-vstrecha-vseh-muzykantov-2023-2/",
						"terms": [
							""
						]
					},
					{
						"id": "250",
						"title": "Всероссийская встреча всех ученых 2023-1",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-24T00:00:00",
						"end": "2023-12-24T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vserossijskaja-vstrecha-vseh-uchenyh-2023-1/",
						"terms": [
							""
						]
					},
					{
						"id": "251",
						"title": "Всероссийская встреча всех ученых 2023-2",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-24T00:00:00",
						"end": "2023-12-24T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vserossijskaja-vstrecha-vseh-uchenyh-2023-2/",
						"terms": [
							""
						]
					},
					{
						"id": "252",
						"title": "Всероссийская встреча всех ученых 2023-3",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-24T00:00:00",
						"end": "2023-12-24T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vserossijskaja-vstrecha-vseh-uchenyh-2023-3/",
						"terms": [
							""
						]
					},
					{
						"id": "253",
						"title": "Всероссийская встреча всех ученых 2023-4",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-24T00:00:00",
						"end": "2023-12-24T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vserossijskaja-vstrecha-vseh-uchenyh-2023-4/",
						"terms": [
							""
						]
					},
					{
						"id": "230",
						"title": "Мероприятие30",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-25T00:00:00",
						"end": "2023-12-25T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/meroprijatie30/",
						"terms": [
							""
						]
					},
					{
						"id": "231",
						"title": "Мероприятие31",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-25T00:00:00",
						"end": "2023-12-25T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/meroprijatie31/",
						"terms": [
							""
						]
					},
					{
						"id": "232",
						"title": "Мероприятие32",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-25T00:00:00",
						"end": "2023-12-25T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/meroprijatie32/",
						"terms": [
							""
						]
					},
					{
						"id": "233",
						"title": "Мероприятие 33",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-12-25T00:00:00",
						"end": "2023-12-27T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/meroprijatie-33/",
						"terms": [
							""
						]
					},
					{
						"id": "234",
						"title": "Мероприятие 34",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-25T00:00:00",
						"end": "2023-12-25T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/meroprijatie-34/",
						"terms": [
							""
						]
					},
					{
						"id": "235",
						"title": "Мероприятие 35",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2023-12-27T00:00:00",
						"end": "2023-12-27T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/meroprijatie-35/",
						"terms": [
							""
						]
					},
					{
						"id": "237",
						"title": "Новый год",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2023-12-28T00:00:00",
						"end": "2023-12-31T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/novyj-god/",
						"terms": [
							""
						]
					},
					{
						"id": "280",
						"title": "Новый год",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2024-01-01T00:00:00",
						"end": "2024-01-01T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/novyj-god-2/",
						"terms": [
							""
						]
					},
					{
						"id": "281",
						"title": "Новый год 2",
						"backgroundColor": "#CF2B64",
						"textColor": "#ffffff",
						"borderColor": "#CF2B64",
						"color": "#ffffff",
						"start": "2024-01-01T00:00:00",
						"end": "2024-01-02T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/novyj-god-2-2/",
						"terms": [
							""
						]
					},
					{
						"id": "294",
						"title": "Новогоднее 2",
						"backgroundColor": "#1A6DB4",
						"textColor": "#ffffff",
						"borderColor": "#1A6DB4",
						"color": "#ffffff",
						"start": "2024-01-04T00:00:00",
						"end": "2024-01-05T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/novogodnee-2/",
						"terms": [
							""
						]
					},
					{
						"id": "295",
						"title": "Новогоднее",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2024-01-04T00:00:00",
						"end": "2024-01-04T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/novogodnee-3/",
						"terms": [
							""
						]
					},
					{
						"id": "296",
						"title": "Новогоднее 3",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2024-01-04T00:00:00",
						"end": "2024-01-06T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/novogodnee-3-2/",
						"terms": [
							""
						]
					},
					{
						"id": "283",
						"title": "Рождество 2",
						"backgroundColor": "#1A6DB4",
						"textColor": "#ffffff",
						"borderColor": "#1A6DB4",
						"color": "#ffffff",
						"start": "2024-01-06T00:00:00",
						"end": "2024-01-07T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/rozhdestvo-2/",
						"terms": [
							""
						]
					},
					{
						"id": "284",
						"title": "Рождество 3",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2024-01-06T00:00:00",
						"end": "2024-01-08T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/rozhdestvo-3/",
						"terms": [
							""
						]
					},
					{
						"id": "282",
						"title": "Рождество",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2024-01-07T00:00:00",
						"end": "2024-01-07T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/rozhdestvo/",
						"terms": [
							""
						]
					},
					{
						"id": "272",
						"title": "Выход 1",
						"backgroundColor": "#70418A",
						"textColor": "#ffffff",
						"borderColor": "#70418A",
						"color": "#ffffff",
						"start": "2024-01-10T00:00:00",
						"end": "2024-01-10T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vyhod-1/",
						"terms": [
							""
						]
					},
					{
						"id": "275",
						"title": "Выход 2",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2024-01-10T00:00:00",
						"end": "2024-01-10T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vyhod-2/",
						"terms": [
							""
						]
					},
					{
						"id": "276",
						"title": "Всемирный фестиваль молодежи в России 2024",
						"backgroundColor": "#000000",
						"textColor": "#ffffff",
						"borderColor": "#000000",
						"color": "#ffffff",
						"start": "2024-01-10T00:00:00",
						"end": "2024-01-10T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vyhod-3/",
						"terms": [
							""
						]
					},
					{
						"id": "278",
						"title": "Всемирный фестиваль молодежи в России 2025",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2024-01-10T00:00:00",
						"end": "2024-01-13T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vsemirnyj-festival-molodezhi-v-rossii-2025/",
						"terms": [
							""
						]
					},
					{
						"id": "279",
						"title": "Всемирный фестиваль молодежи в России 2026",
						"backgroundColor": "#1A6DB4",
						"textColor": "#ffffff",
						"borderColor": "#1A6DB4",
						"color": "#ffffff",
						"start": "2024-01-10T00:00:00",
						"end": "2024-01-11T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/vsemirnyj-festival-molodezhi-v-rossii-2026/",
						"terms": [
							""
						]
					},
					{
						"id": "285",
						"title": "Праздник",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2024-01-16T00:00:00",
						"end": "2024-01-16T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/prazdnik/",
						"terms": [
							""
						]
					},
					{
						"id": "286",
						"title": "Праздник 2",
						"backgroundColor": "#1A6DB4",
						"textColor": "#ffffff",
						"borderColor": "#1A6DB4",
						"color": "#ffffff",
						"start": "2024-01-16T00:00:00",
						"end": "2024-01-17T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/prazdnik-2/",
						"terms": [
							""
						]
					},
					{
						"id": "287",
						"title": "День подарков",
						"backgroundColor": "#35B5E9",
						"textColor": "#ffffff",
						"borderColor": "#35B5E9",
						"color": "#ffffff",
						"start": "2024-01-21T00:00:00",
						"end": "2024-01-21T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/den-podarkov/",
						"terms": [
							""
						]
					},
					{
						"id": "288",
						"title": "День подарков 2",
						"backgroundColor": "#1A6DB4",
						"textColor": "#ffffff",
						"borderColor": "#1A6DB4",
						"color": "#ffffff",
						"start": "2024-01-21T00:00:00",
						"end": "2024-01-22T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/den-podarkov-2/",
						"terms": [
							""
						]
					},
					{
						"id": "289",
						"title": "День подарков 3",
						"backgroundColor": "#1A6DB4",
						"textColor": "#ffffff",
						"borderColor": "#1A6DB4",
						"color": "#ffffff",
						"start": "2024-01-23T00:00:00",
						"end": "2024-01-24T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/den-podarkov-3/",
						"terms": [
							""
						]
					},
					{
						"id": "290",
						"title": "День подарков 4",
						"backgroundColor": "#E21D5A",
						"textColor": "#ffffff",
						"borderColor": "#E21D5A",
						"color": "#ffffff",
						"start": "2024-01-25T00:00:00",
						"end": "2024-01-27T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/den-podarkov-4/",
						"terms": [
							""
						]
					},
					{
						"id": "292",
						"title": "дни",
						"backgroundColor": "#4B529C",
						"textColor": "#ffffff",
						"borderColor": "#4B529C",
						"color": "#ffffff",
						"start": "2024-01-28T00:00:00",
						"end": "2024-01-31T20:59:59",
						"display": "block",
						"showtime": 1,
						"ts": 0.3333333333333333,
						"image_src": "",
						"url": "https://calendar-d-economy.9-lines.com/meropr/dni/",
						"terms": [
							""
						]
					}
				]

				successCallback(doc);*/

				$.ajax({
					type: "post",
					data: {PAGE: 1, FILTER: 1},
					dataType: "json",
					url: '/ajax/getevents.php',
					success: function (data) {
						successCallback(data);
						console.log(data);
					},
					error: function (data) {
						console.log(data);
					}
				});
			},
			eventSourceSuccess: function() {
				setTimeout(() => {
					$('.fc-list-table .fc-list-day').each(function () {
						$(this).prev('.fc-list-event').addClass('is-last');
					})
				},50)
			}
		});
		window.calendar = calendar;

		calendar.render();

		$(document).on('click', '.fc-popover-close, .fc-popover-bg', function (e) {
			$('.fc-popover').remove();
		});

		if (jQuery(window).width() < 1025) {
			$('.fc-list-table .fc-list-day').each(function () {
				$(this).prev('.fc-list-event').addClass('is-last');
			})
		}

		jQuery(window).resize(function () {
			calendar.refetchEvents();

			if (jQuery(window).width() < 1025) {
				$('.fc-list-table .fc-list-day').each(function () {
					$(this).prev('.fc-list-event').addClass('is-last');
				})
			}
		});

		$(document).on('click', '.fc-event-main-link', function (e) {
			e.preventDefault();

			let dayCell = $(this).closest('.fc-daygrid-day');
			let dayCellDate = $(this).data('date');
			let dayCellDateString = new Date(dayCellDate).toDateString();

			let events = calendar.getEvents();
			let dayEvents = events.filter(function (event) {
				let start = new Date(event._instance.range.start).toDateString();
				let end = new Date(event._instance.range.end).toDateString();

				return Date.parse(start) <= Date.parse(dayCellDateString) && Date.parse(end) >= Date.parse(dayCellDateString);
			});
			let sortedEvents = dayEvents.sort(function(a, b) {
				let durationA = moment(a.end).diff(moment(a.start));
				let durationB = moment(b.end).diff(moment(b.start));
				return durationB - durationA;
			});

			console.log('sortedEvents',sortedEvents)

			let eventCount = dayEvents.length;

			if (dayCell) {
				if (eventCount >= 2) {

					$('.fc-popover').remove();

					let day = $(`.fc-daygrid-day[data-date="${dayCellDate}"]`);
					let dayDate = day.find('.fc-daygrid-day-number').text();

					let eventsHtml = sortedEvents.map((event) => {
						return `
								<div class="fc-daygrid-event-harness">
									<a
										href="${event.url}"
										target="_blank"
										class="fc-daygrid-event fc-daygrid-block-event fc-h-event fc-event fc-event-start fc-event-end fc-event-future"
										style="background-color: ${event.backgroundColor};"
									>
										<div class="fc-event-main">
											<div class="fc-event-main-frame">
												<div class="fc-event-time">09:30 - 17:00</div>
												<div class="fc-event-title-container">
													<div class="fc-event-title fc-sticky">${event.title}</div>
												</div>
											</div>
										</div>
									</a>
								</div>
							`;
					});

					let popup =
						`
								<div class="fc-popover fc-more-popover fc-day fc-day-tue fc-day-future">
									<div class="fc-popover-header ">
										<span class="fc-popover-title">${dayDate}</span>
										<span class="fc-popover-close fc-icon fc-icon-x"></span>
									</div>
									<div class="fc-popover-body">${eventsHtml.join('')}</div>
									<div class="fc-popover-bg"></div>
								</div>
						`;

					day.append(popup);

					let popover = $('.fc-popover');

					let dayRect = day[0].getBoundingClientRect();
					let containerRect = $('.fc-daygrid-body')[0].getBoundingClientRect();
					let hoverTop;
					let hoverLeft;

					if (dayRect.bottom + popover.outerHeight() > containerRect.bottom) {
						hoverTop = (dayRect.bottom - dayRect.top) + dayRect.top - containerRect.top - popover.outerHeight() - 10; // нижняя граница
					} else {
						hoverTop = dayRect.top - containerRect.top; // верхняя граница
					}

					if (dayRect.right + popover.outerWidth() > containerRect.right) {
						hoverLeft = dayRect.left - containerRect.left - popover.outerWidth() - 10; // Влево
					} else if (dayRect.left - popover.outerWidth() < containerRect.left) {
						hoverLeft = dayRect.left - containerRect.left + dayRect.width; // Вправо
					} else {
						hoverLeft = dayRect.left - containerRect.left + dayRect.width; // Вправо
					}

					popover.css({ top: hoverTop, left: hoverLeft });

				} else {
					window.open($(this).attr('href'), "_blank");
				}
			}
		})

		$('.fc-prev-button').click(() => {
			if (jQuery(window).width() > 1024) {
				// calendar.prev();
				/*if (calendar.view.type !== 'listWeek') {
					calendar.changeView('listWeek');
				} else {
					calendar.prev();
				}*/
			}
		});

		$('.fc-next-button').click(() => {
			if (jQuery(window).width() > 1024) {
				// calendar.next();
				/*if (calendar.view.type !== 'listWeek') {
					calendar.changeView('listWeek');
					calendar.next();
				} else {
				}*/
			}
		});
	}
}

function destroy(container) {

}

export default {
	init,
	destroy,
};
