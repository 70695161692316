import './vendor';
import './helpers';
import './components/social';
import {ieFix} from './vendor/ie-fix';
import {vhFix} from './vendor/vh-fix';
import {actualYear} from './modules/actualYear';
import video from './components/video';
import calendar from "./components/calendar";
import lazyLoading from './modules/lazyLoading';
//import scrollToAnchor from './modules/scrollToAnchor';
import Swiper from 'swiper';
import {Autoplay, Navigation, Pagination, Grid} from 'swiper/modules';
import daterangepicker from './vendor/daterangepicker';
import select2 from 'select2';
import vars from "./helpers";
import AirDatepicker from 'air-datepicker';
import el from "air-datepicker/locale/el";

ieFix();
vhFix();
actualYear();
//scrollToAnchor.init();

lazyLoading.init();

const $document = $(document);
const $window = $(window);
const $html = $('html');
const $body = $('body');

let app = {
	global: {
		init() {
			video.init();
			calendar.init();
		},
	},
	introSlider: {
		init($module) {
			const slider = new Swiper($module.find('.swiper')[0], {
				modules: [Autoplay, Navigation, Pagination],
				// autoHeight: true,
				slidesPerView: 1,
				spaceBetween: 10,
				loop: true,
				autoplay: {
					delay: 3000,
					disableOnInteraction: false,
					// pauseOnMouseEnter: true,
				},
				navigation: {
					prevEl: $module.find('.home__intro-arrow-prev')[0],
					nextEl: $module.find('.home__intro-arrow-next')[0],
				},

				on: {
					init: () => {
						$module.find('.home__intro-pagination')[0].textContent = `1 / ${$module.find(".swiper-slide").length}`;
					},
					slideChange: () => {
						$module.find('.home__intro-pagination')[0].textContent = `${slider.realIndex + 1} / ${$module.find(".swiper-slide").length}`;
					},
					autoplayTimeLeft(s, time, progress) {
						$module.find('.swiper-pagination-bullet-active').css('--progress', `${(1 - progress) * 100}%`);
					}
				},
				pagination: {
					el: $module.find('.home__intro-progressbar')[0],
				},
			});
		},
	},
	filters: {
		init($module) {
			const $calendarItem = $module.find('.home__filters-calendar');
			const $calendar = $module.find('.home__filters-calendar input');
			const $selects = $module.find('.home__filters-select select');
			const $view = $module.find('.home__filters-select--view');
			const $events = $module.find('.home__filters-select--events');
			const $tag = $module.find('.home__filters-tag');
			let clickCount = 0;
			let tagLeft = 0;

			const datepicker = new AirDatepicker($calendar[0], {
				classes: 'datepicker',
				range: true,
				multipleDatesSeparator: ' - ',
				autoClose: true,
				// inline: true,
				dateFormat: 'yyyy-MM-dd',
				onHide: function onHide(isFinished) {
					if (isFinished) {
						$calendarItem.removeClass('is-active');
						clickCount = 0;
						// setfilterrs(1);
					}
				},
				navTitles: {
					days: '<p>MMMM</p>',
				},
				prevHtml: '<svg xmlns="http://www.w3.org/2000/svg" width="6" height="8" viewBox="0 0 6 8" fill="none"><path d="M0.5 4L5.75 0.968911L5.75 7.03109L0.5 4Z" fill="#252525"/></svg>',
				nextHtml: '<svg xmlns="http://www.w3.org/2000/svg" width="6" height="8" viewBox="0 0 6 8" fill="none"><path d="M5.5 4L0.25 7.03109L0.25 0.968911L5.5 4Z" fill="#252525"/></svg>',
				onSelect: ({date, formattedDate, datepicker}) => {
					let start = formattedDate[0];
					let end = formattedDate[1];

					window.calendar.changeView('list', {
						start: start,
						end: end ? end : start,
					});

					$('.fc-list-table .fc-list-day').each(function () {
						$(this).prev('.fc-list-event').addClass('is-last');
					})
				}
			})

			$calendar.on('click', () => {
				clickCount++;
				$calendarItem.addClass('is-active');


				if (clickCount === 2) {
					datepicker.hide();
					$calendarItem.removeClass('is-active');
					clickCount = 0;
				}
			});

			if ($tag[0]) {
				$tag.on('click', () => {
					$tag.toggleClass('is-active');
				});

				$document.on('click', (e) => {
					let $target = $(e.target);

					if (!$target.closest('.home__filters-tag').length) {
						$tag.removeClass('is-active');
					}
				})

				if (vars.isMobile()) {
					if ($module[0].getBoundingClientRect().left === $tag[0].getBoundingClientRect().left) {
						tagLeft = 0
					} else {
						tagLeft = $tag[0].getBoundingClientRect().left - $module[0].getBoundingClientRect().left;
					}
					$module.find('.home__filters-tag').find('.home__filters-tag-wrapper').css('left', `-${tagLeft}px`);
				}
			}

			// const homeTitle = document.querySelector('.home__hero').querySelector('.home__title').getBoundingClientRect();

			// $calendar.daterangepicker({
			// 	locale: {
			// 		cancelLabel: 'Clear',
			// 		format: 'DD.MM.YYYY',
			// 		daysOfWeek: [
			// 			'Вс',
			// 			'Пн',
			// 			'Вт',
			// 			'Ср',
			// 			'Чт',
			// 			'Пт',
			// 			'Сб',
			// 		],
			// 		monthNames: [
			// 			'Январь',
			// 			'Февраль',
			// 			'Март',
			// 			'Апрель',
			// 			'Май',
			// 			'Июнь',
			// 			'Июль',
			// 			'Август',
			// 			'Сентябрь',
			// 			'Октябрь',
			// 			'Ноябрь',
			// 			'Декабрь',
			// 		],
			// 		//firstDay: 1,
			// 	},
			// 	autoApply: true,
			// 	autoUpdateInput: false,
			// 	linkedCalendars: false,
			// 	//minDate: new Date(),
			// 	parentEl: $calendar.parent(),
			// 	startDate: new Date(),
			// }, function(start, end, label) {
			// 	//console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
			// });
			//
			// $calendar.on('apply.daterangepicker', function(ev, picker) {
			// 	$(this).val(picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY'));
			// });

			$selects.each((index, item) => {
				const $select = $(item);

				$select.select2({
					minimumResultsForSearch: Infinity,
					// dropdownParent: $select.parent(),
					dropdownAutoWidth : true,
					width: '100%'
				});
			})

			$view.on('change', function (e) {
				if(!vars.isMobile()) {
					let value = e.target.value;

					$('[data-tabs="' + value + '"]').removeClass('is-hidden').siblings().addClass('is-hidden');

					if (value === 'calendar') {
						calendar.init();
						$events.hide();
						$calendarItem.hide();

					} else {
						$events.show();
						$calendarItem.show();
					}
				}
			});

			if(vars.isMobile()) {
				$(`[data-tabs="news"]`).hide();
				$(`[data-tabs="calendar"]`).show();

				$calendar.attr('placeholder', 'Выбрать даты');
				$('.home__calendar').removeClass('is-hidden');
			}
		}
	},
	partnersSlider: {
		init($module) {
			let length = 5;
			let rows = 1;
			let height = false;
			let fill = 'column';

			if (vars.isMobile()) {
				length = 4
			}

			if ($module.find(".swiper-slide").length > length) {
				rows = 2;
				fill = 'row';
			} else {
				height = true;
			}

			const slider = new Swiper($module.find('.swiper')[0], {
				modules: [Navigation, Pagination, Grid],
				slidesPerView: 4,
				autoHeight: height,
				grid: {
					rows: rows,
					fill: fill,
				},
				breakpoints: {
					1024: {
						slidesPerView: 5,
					},
				},
				navigation: {
					prevEl: $module.find('.detail__partners-arrow-prev')[0],
					nextEl: $module.find('.detail__partners-arrow-next')[0],
				},
				pagination: {
					el: $module.find('.detail__partners-progressbar')[0],
					type: 'bullets',
				},
			});
		},
	},
	detailSlider: {
		init($module) {
			const slider = new Swiper($module.find('.swiper')[0], {
				modules: [Navigation],
				slidesPerView: 1,
				spaceBetween: 10,
				allowTouchMove: !$module.data('slider'),
				breakpoints: {
					1024: {
						slidesPerView: $module.data('slider') ? 1 : 2,
						spaceBetween: 20,
					},
				},
				navigation: {
					prevEl: $module.find('.detail__slider-arrow-prev')[0],
					nextEl: $module.find('.detail__slider-arrow-next')[0],
				},
			});

			if($module.find('.swiper-slide').length === 1) {
				$module.find('.detail__slider-arrows').find('button').addClass('is-hidden')
				// $('.swiper-wrapper').addClass( "disabled" );
				// $('.swiper-pagination').addClass( "disabled" );
			}

			slider.on('slideChange', () => {
				$module.find('.video-js video').each((index, item) => {
					item.pause();
				});
			});
		},
	},
	detailSections: {
		init($module) {
			const $itemHidden = $module.find('.detail__sections-item--hidden');
			const $more = $module.find('.detail__sections-more a');

			$more.on('click', (e) => {
				e.preventDefault();

				$itemHidden.removeClass('detail__sections-item--hidden');
				$more.closest('.detail__sections-more').addClass('is-hidden');
			})
		},
	},
}

app.global.init();

window.initApps = () => {
	$('[data-app]').each((index, item) => {
		let $this = $(item);
		let split = $this.data('app').split('||');

		$.each(split, (appIndex, appName) => {
			let appNameCamel = false;

			if (appName.indexOf('-') !== -1) {
				appNameCamel = appName.replace(/(-|\s)(\S)/ug, (m) => m.toUpperCase()).replace(/-/ug, '');
			}

			if (app[appName] && $this.data(`${appName}-init`) !== true) {
				app[appName].init($this);
				$this.data(`${appName}-init`, true);
			} else if (app[appNameCamel] && $this.data(`${appName}-init`) !== true) {
				app[appNameCamel].init($this);
				$this.data(`${appName}-init`, true);
			}
		});
	});
};

initApps();
