import 'core-js/stable';
import 'regenerator-runtime/runtime';
import svg4everybody from 'svg4everybody';
import $ from 'jquery';
import moment from 'moment/moment';
import videojs from 'video.js';
import 'videojs-youtube/dist/Youtube'
import objectFitImages from 'object-fit-images';
import objectFitVideos from 'object-fit-videos';

svg4everybody();
objectFitImages();
// objectFitVideos();

window.$ = $;
window.jQuery = $;
window.moment = moment;
window.objectFitImages = objectFitImages;
window.objectFitVideos = objectFitVideos;
window.videoJs = videojs;

require('ninelines-ua-parser');
